.App {
  text-align: center;
} 

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


/* #waveform ::part(cursor):before {
  content: '🏄';
}
#waveform ::part(region) {
  font-family: fantasy;
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login-bg {
  background: linear-gradient(hsla(0, 0%, 100%, 0.6), hsla(0, 0%, 100%, 0.6)),
    url(/src/assets/img/bg-login.jpeg);
  background-position: 50%;
  background-size: cover;
  height: 100vh;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.image-container {
  padding: 10px;
}

.rotating-image {
  animation: rotate 5s infinite linear;
  transform-origin: center;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ql-editor {
  min-height: 400px;
}

.MuiInputBase-input:focus {
  border: 0px solid gray !important;
  outline: 0 !important;
  outline-offset: 0px;
  --tw-ring-shadow: none;
  box-shadow: none !important;
}


.MuiDateRangeCalendar-root > :first-child {
  display: none !important;
}

/* Customize the entire scrollbar */
::-webkit-scrollbar {
  width: 0px; /* Width of the scrollbar */
}


